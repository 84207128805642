import { Typography } from '@material-tailwind/react';
import React, { useContext, useEffect, useState } from 'react'
import { CartContext } from '../../contexts/CartContext';
import { API_LINK } from '../../apiConfig';
import { IoMdAdd, IoMdClose, IoMdRemove } from 'react-icons/io';
import { BsCartX, BsArrowRight } from "react-icons/bs";
import { Link } from 'react-router-dom';


const TABLE_HEAD = ["", "Produit", "Prix", "Quantité", "Total"];

const ShoppingCart = () => {
    const { cart, total, removeFromCart, increaseAmount, decreaseAmount } = useContext(CartContext);

    const [isActive, setIsActive] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        window.addEventListener("scroll", () => {
            window.scrollY > 60 ? setIsActive(true) : setIsActive(false);
        });
    });

    return (
        <div>
            <div
                className={`${isActive ? 'h-[72px]' : 'h-[88px]'} top-0 bg-white fixed w-full transition-all`}
            ></div>

            <div className='w-full p-10 bg-primary mt-[100px] text-white flex sm:flex-row flex-col justify-evenly items-center'>
                <Typography variant="lead">
                    <span className="border-b-2 border-white">MON PANIER</span>
                </Typography>
                <BsArrowRight className="text-2xl sm:block hidden" />
                <Typography variant="lead" className='sm:block hidden'>
                    <span>VALIDATION DE LA COMMAND</span>
                </Typography>
                <BsArrowRight className="text-2xl sm:block hidden" />
                <Typography variant="lead" className='sm:block hidden'>
                    <span>COMMANDE TERMINÉE</span>
                </Typography>
            </div>

            {cart.length !== 0 ? (<div className='flex md:flex-row flex-col w-full h-screen text-info'>
                <div className='md:w-[60%] p-8 w-full overflow-x-auto'>
                    <table className="w-full min-w-max table-auto text-center">
                        <thead>
                            <tr>
                                {TABLE_HEAD.map((head) => (
                                    <th
                                        key={head}
                                        className="border-y border-gray-400 p-4"
                                    >
                                        <Typography
                                            variant="paragraph"
                                            className="leading-none text-info font-medium"
                                        >
                                            {head}
                                        </Typography>
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {cart.map((item) => {
                                const classes = "p-4 border-b border-gray-400";
                                return (
                                    <tr key={item.id} className="text-info">
                                        <td className={classes}>
                                            <div className="flex items-center gap-3">
                                                <div
                                                    onClick={() => removeFromCart(item.id)}
                                                    className="text-xl cursor-pointer"
                                                >
                                                    <IoMdClose className="text-gray-500 hover:text-red-500 transition" />
                                                </div>
                                            </div>
                                        </td>
                                        <td className={classes}>
                                            <div className="flex items-center gap-3">
                                                <img src={`${API_LINK}/${item.images[0]}`} alt={item.name} className='w-16 h-16' />
                                                <Typography
                                                    variant="paragraph"
                                                    color="blue-gray"
                                                    className="capitalize"
                                                >
                                                    {item.name}
                                                </Typography>
                                            </div>
                                        </td>

                                        <td className={classes}>
                                            <div className="flex items-center gap-3">
                                                <Typography
                                                    variant="paragraph"
                                                    color="blue-gray"
                                                    className="capitalize"
                                                >
                                                    {item.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} DZD
                                                </Typography>
                                            </div>
                                        </td>

                                        <td className={classes}>
                                            <div className="flex items-center gap-3">
                                                <div className="flex flex-1 max-w-[100px] items-center h-full border text-info font-medium">
                                                    <div onClick={() => decreaseAmount(item.id)} className="h-full flex-1 flex justify-center items-center cursor-pointer">
                                                        <IoMdRemove />
                                                    </div>
                                                    <div className="h-full flex justify-center items-center px-2">
                                                        {item.amount}
                                                    </div>
                                                    <div onClick={() => increaseAmount(item.id)} className="h-full flex flex-1 justify-center items-center cursor-pointer">
                                                        <IoMdAdd />
                                                    </div>
                                                </div>
                                            </div>
                                        </td>

                                        <td className={classes}>
                                            <div className="flex items-center gap-3">
                                                <Typography
                                                    variant="paragraph"
                                                    color="blue-gray"
                                                    className="capitalize"
                                                >
                                                    {(item.price * item.amount).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} DZD
                                                </Typography>
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>

                <div className='md:w-[40%] w-full p-8'>
                    <Typography
                        className='ml-1 mb-2 uppercase text-center'
                        variant='h4'
                    >
                        Votre panier
                    </Typography>
                    <div className='p-2 border border-gray-400 mb-10'>
                        <div className='flex justify-between items-center p-2 border-b-2'>
                            <Typography
                                className='ml-1 uppercase text-center'
                                variant='h6'
                            >
                                Produit
                            </Typography>
                            <Typography
                                className='ml-1 uppercase text-center'
                                variant='h6'
                            >
                                Sous-total
                            </Typography>
                        </div>

                        {cart.map((item) => (
                            <div key={item.id} className='flex justify-between items-center p-2 border-b'>
                                <Typography
                                    className='ml-1 uppercase text-center font-light'
                                    variant='h6'
                                >
                                    {item.name} X {item.amount}
                                </Typography>
                                <Typography
                                    className='ml-1 uppercase text-center font-light'
                                    variant='h6'
                                >
                                    {(item.price * item.amount).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} DZD
                                </Typography>
                            </div>
                        ))}


                        <div className='flex justify-between items-center p-2'>
                            <Typography
                                className='ml-1 text-center capitalize'
                                variant='h6'
                            >
                                Total
                            </Typography>
                            <Typography
                                className='ml-1 uppercase text-center'
                                variant='h5'
                            >
                                {total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} DZD
                            </Typography>
                        </div>
                    </div>

                    <Link to={'/place-order'}
                        className="bg-primary flex p-3 justify-center items-center text-white w-full font-medium"
                    >
                        Valider Commander
                    </Link>

                </div>

            </div>)
                :
                (
                    <div className='flex flex-col justify-center items-center h-screen text-info'>
                        <BsCartX size={180} className='text-gray-200 m-5' />
                        <Typography variant='h3' className='text-center'>
                            Votre panier est actuellement vide.
                        </Typography>
                        <div className='m-5'>
                            <Link to={'/'}
                                className="bg-primary flex p-3 justify-center items-center text-white w-full font-medium uppercase"
                            >
                                Retour à la boutique
                            </Link>
                        </div>
                    </div>
                )}
        </div>
    )
}

export default ShoppingCart;