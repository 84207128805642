import React from "react";
import {
    Dialog,
    DialogBody,
    IconButton
} from "@material-tailwind/react";

import { API_LINK } from "../../../apiConfig";
import { BsX } from "react-icons/bs";

export const ShowAdvertisement = ({ handleOpen, open, advertisement }) => {

    return (
        <>
            <Dialog
                size="xs"
                open={open}
                handler={() => { handleOpen() }}
                animate={{
                    mount: { scale: 1, y: 0 },
                    unmount: { scale: 0.9, y: 0 },
                }}
                className="rounded-none transition-all duration-500"
            >
                <DialogBody className="p-0 m-0 rounded-none">
                    <div className="relative">
                        <img src={advertisement && `${API_LINK}/${advertisement?.imageUrl}`} alt="advertisement" className="w-full" />
                        <div className="absolute top-0 right-0">
                            <IconButton onClick={() => { handleOpen() }} variant="text" className="rounded-none">
                                <BsX size={32} />
                            </IconButton>
                        </div>
                    </div>
                </DialogBody>
            </Dialog >
        </>
    );
}