import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { API_LINK } from '../../apiConfig';
import Product from '../../components/Product';
import { Typography } from '@material-tailwind/react';

import { BsSearch } from "react-icons/bs";


const CandleReed = () => {

    const [isActive, setIsActive] = useState(false);
    const [products, setProducts] = useState([]);
    const [sortOrder, setSortOrder] = useState();


    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        window.addEventListener("scroll", () => {
            window.scrollY > 60 ? setIsActive(true) : setIsActive(false);
        });
    });

    useEffect(() => {
        axios.get(`${API_LINK}/products/search?category=candle-reed&type=undefined&specification=undefined&priceOrder=${sortOrder}`).then(({ data }) => {
            setProducts(data);
        }).catch((error) => {
            console.log(error);
        })
    }, [sortOrder]);


    return (
        <div>
            <div className={`top-0 bg-white flex justify-center items-center h-screen w-full transition-all`}>
                <h1 className='text-5xl font-secondary'>
                    Bientôt
                </h1>
            </div>
        </div>
    )
}

export default CandleReed