import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";

import { BsPlus, BsEyeFill } from "react-icons/bs";

import { CartContext } from "../contexts/CartContext";
import { API_LINK } from "../apiConfig";
import { SidebarContext } from "../contexts/SidebarContext";

const Product = ({ product }) => {
  const { addToCart } = useContext(CartContext);
  const { setIsOpen } = useContext(SidebarContext);

  const [hovered, setHovered] = useState(false);

  const handleAddToCart = (event) => {
    event.preventDefault(); // Prevent the default behavior of the button click event
    addToCart(product, product.id, 1); // Add the product to the cart
    setIsOpen(true);
  };

  // destructure product
  // const { id, image, category, title, price } = product;
  return (
    <Link to={`/product/${product.id}`}>
      {/* bg-gradient-to-tr from-secondary */}
      <div className="border border-[#e4e4e4] mb-4 relative overflow-hidden group transition hover:shadow-xl hover:shadow-gray-100 bg-gradient-to-tr from-[#E6D9CF] via-[#F8F5F3] to-[#F8F5F3]"
        onMouseEnter={() => setHovered(true)} // Set hovered state to true on mouse enter
        onMouseLeave={() => setHovered(false)} // Set hovered state to false on mouse leave
      >
        <div className="w-full h-full flex justify-center items-center">
          {/* image */}
          <div className="mx-auto flex justify-center items-center">
            <img
              // className="max-h-[160px] group-hover:scale-110 transition duration-300"
              className="max-w-full group-hover:scale-110 transition duration-300"

              // src={`${API_LINK}/${product.images[0]}`}
              src={`${API_LINK}/${hovered ? product.images[1] : product.images[0]}`}
              alt={product.name}
            />
          </div>
        </div>
        {/* buttons */}
        <div className="absolute top-6 -right-11 group-hover:right-5 p-2 flex flex-col justify-center items-center gap-y-2 opacity-0 group-hover:opacity-100 transition-all duration-300">
          <button onClick={handleAddToCart}>
            <div className="flex justify-center items-center text-white w-12 h-12 bg-[#E1B6A5]">
              <BsPlus className="text-3xl" />
            </div>
          </button>
          {/* <Link
            to={`/product/${product.id}`}
            className="w-12 h-12 bg-white flex justify-center items-center text-primary drop-shadow-xl"
          >
            <BsEyeFill />
          </Link> */}
        </div>
      </div>
      {/* category, title & price */}
      <div>
        <div className="tex-sm capitalize text-gray-500 mb-1">{product.category}</div>
        <Link to={`/product/${product.id}`}>
          <h2 className="font-semibold capitalize mb-1">{product.name}{product.specification && ` - ${product.specification}`}</h2>
        </Link>

        <div className="flex justify-between items-center">
          <h2>{product.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} <span className="font-semibold">DZD</span></h2>
          <div>
            {product.stockQuantity === 0 && <h2 className="text-red-400">Rupture de stock</h2>
              // : <h2>{product.stockQuantity} Unité(s)</h2>
            }
          </div>
        </div>
      </div>
    </Link>
  );
};

export default Product;
