import React, { useEffect, useRef, useState } from 'react';
import { Typography } from '@material-tailwind/react';
import { motion, useInView, useAnimation } from 'framer-motion'
import emailjs from '@emailjs/browser'

import wilayas from '../../data/wilayas.json'


import banner from '../../img/premium/banner.png';
import grid1 from '../../img/premium/grid1.png';
import grid2 from '../../img/premium/grid3.png';
import grid3 from '../../img/premium/grid2.png';

import icon1 from '../../img/premium/Premium Icon/1.png';
import icon2 from '../../img/premium/Premium Icon/2.png';
import icon3 from '../../img/premium/Premium Icon/3.png';
import icon4 from '../../img/premium/Premium Icon/4.png';
import icon5 from '../../img/premium/Premium Icon/5.png';

const Premium = () => {
    const bannerControls = useAnimation()
    const refBanner = useRef(null);
    const isInViewBanner = useInView(refBanner);

    const textControls = useAnimation()
    const refText = useRef(null);
    const isInViewText = useInView(refText);

    const signatureControls = useAnimation()
    const refSignature = useRef(null);
    const isInViewSignature = useInView(refSignature);


    const contactControls = useAnimation()
    const refContact = useRef(null);
    const isInViewContact = useInView(refContact);


    useEffect(() => {
        if (isInViewBanner) {
            bannerControls.start("visible")
        }

        if (isInViewText) {
            textControls.start("visible")
        }

        if (isInViewSignature) {
            signatureControls.start("visible")
        }

        if (isInViewContact) {
            contactControls.start("visible")
        }
    }, [isInViewBanner, isInViewText, isInViewSignature, isInViewContact])




    const form = useRef();
    const [isActive, setIsActive] = useState(false);

    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [company, setCompany] = useState('');
    const [wilaya, setWilaya] = useState('');
    const [message, setMessage] = useState('');

    const [isFirstnameEmpty, setIsFirstnameEmpty] = useState(false);
    const [isLastnameEmpty, setIsLastnameEmpty] = useState(false);
    const [isPhoneEmpty, setIsPhoneEmpty] = useState(false);
    const [isEmailEmpty, setIsEmailEmpty] = useState(false);
    const [isCompanyEmpty, setIsCompanyEmpty] = useState(false);
    const [isWilayaEmpty, setIsWilayaEmpty] = useState(false);
    const [isMessageEmpty, setIsMessageEmpty] = useState(false);

    const validateInputs = () => {
        setIsFirstnameEmpty(firstname.trim() === '');
        setIsLastnameEmpty(lastname.trim() === '');
        setIsPhoneEmpty(phone.trim() === '');
        setIsEmailEmpty(email.trim() === '');
        setIsCompanyEmpty(company.trim() === '');
        setIsWilayaEmpty(wilaya.trim() === '');
        setIsMessageEmpty(message.trim() === '');
        return (
            firstname.trim() !== '' &&
            lastname.trim() !== '' &&
            phone.trim() !== '' &&
            email.trim() !== '' &&
            company.trim() !== '' &&
            wilaya.trim() !== '' &&
            message.trim() !== ''
        );
    };


    const contactHandler = async (e) => {
        e.preventDefault();
        if (validateInputs()) {
            emailjs.sendForm('service_p5730n7', 'template_lpo71hr', form.current, 'FeCk0LZplRQcq7GtW')
                .then((result) => {
                    console.log(result.text);
                    defaultValuesForInputs();
                }, (error) => {
                    console.log(error.text);
                });

        } else {
            alert('Veuillez remplir tous les champs')
        }
        e.target.reset();
    }

    const defaultValuesForInputs = () => {
        setFirstname('');
        setLastname('');
        setEmail('');
        setPhone('');
        setCompany('');
        setWilaya('');
        setMessage('');
    }


    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        window.addEventListener("scroll", () => {
            window.scrollY > 60 ? setIsActive(true) : setIsActive(false);
        });
    });


    return (
        <div>
            <div
                className={`${isActive ? 'h-[72px]' : 'h-[88px]'} top-0 bg-white fixed w-full transition-all`}
            ></div>

            {/* <div className='sm:mt-[110px] mt-[96px] text-info'> */}
            <div className='text-info'>
                {/* <img src={banner} className='sm:h-full h-screen bg-cover bg-center object-cover' /> */}
                <div ref={refBanner}>
                    <motion.section
                        variants={{
                            hidden: { opacity: 0, y: 75 },
                            visible: { opacity: 1, y: 0 }
                        }}
                        initial='hidden'
                        animate={bannerControls}
                        transition={{ duration: 0.5, delay: 0.25 }}
                        className='relative'>
                        <img src={banner} className="w-full h-screen object-cover" />
                        <div className='absolute inset-0 w-full sm:flex hidden justify-center items-center'>
                            <div className='flex flex-col justify-between items-center h-full'>
                                <div></div>
                                <Typography className='flex flex-col items-center text-[#523529] sm:text-3xl text-xl font-semibold font-secondary text-center p-5'>
                                    <span className='tracking-widest mb-2'>EXPLOREZ</span>
                                    <span className='tracking-widest'>LA PUISSANCE DU MARKETING OLFACTIF</span>
                                </Typography>
                            </div>
                        </div>
                    </motion.section>
                </div>

                <section className="mt-20">
                    <div className="container mx-auto">
                        <Typography variant='h3' className='text-primary tracking-wider font-secondary text-center font-normal'>
                            A la croisée du parfum et de la technologie de pointe,
                            notre objectif est d’accompagner chaque client dans
                            son projet pour développer leur univers olfactif.
                        </Typography>
                    </div>
                </section>

                <div ref={refText}>
                    <motion.section
                        variants={{
                            hidden: { opacity: 0, y: 75 },
                            visible: { opacity: 1, y: 0 }
                        }}
                        initial='hidden'
                        animate={textControls}
                        transition={{ duration: 0.5, delay: 0.25 }} className="mt-20">
                        <div className="container mx-auto flex flex-col items-center">
                            <Typography variant='h3' className='text-black tracking-wider font-secondary text-center font-medium'>
                                UN SERVICE AU DELA DE VOS ATTENTES
                            </Typography>
                            <Typography variant='lead' className='text-black text-center font-normal'>
                                Nous proposons à nos clients un service de location, un package qui comprends
                            </Typography>


                            <div className='flex flex-row flex-wrap items-start justify-center gap-8 mt-10'>
                                <div className='flex flex-col items-center justify-center w-36'>
                                    <img src={icon1} className='h-full mb-2' />
                                    <Typography className='font-medium text-[#B5745B]'>
                                        Le Diffuseur
                                    </Typography>
                                </div>

                                <div className='flex flex-col items-center justify-center w-36'>
                                    <img src={icon2} className='h-full mb-2' />
                                    <Typography className='font-medium text-[#B5745B]'>
                                        Le Consommable
                                    </Typography>
                                </div>

                                <div className='flex flex-col items-center justify-center w-36'>
                                    <img src={icon3} className='h-full mb-2' />
                                    <Typography className='font-medium text-[#B5745B]'>
                                        La Maintenance
                                    </Typography>
                                </div>

                                <div className='flex flex-col items-center justify-center w-36'>
                                    <img src={icon4} className='h-full mb-2' />
                                    <Typography className='font-medium text-[#B5745B] text-center'>
                                        L'assurance sur le produit
                                    </Typography>
                                </div>

                                <div className='flex flex-col items-center justify-center w-36'>
                                    <img src={icon5} className='h-full mb-2' />
                                    <Typography className='font-medium text-[#B5745B]'>
                                        La Disponibilité
                                    </Typography>
                                </div>

                            </div>
                        </div>
                    </motion.section>
                </div>


                <div ref={refSignature}>
                    <motion.section
                        variants={{
                            hidden: { opacity: 0, y: 75 },
                            visible: { opacity: 1, y: 0 }
                        }}
                        initial='hidden'
                        animate={signatureControls}
                        transition={{ duration: 0.5, delay: 0.25 }}
                        className="mt-20">
                        <div className="container mx-auto grid grid-cols-1 sm:grid-cols-2 gap-8">

                            <div>
                                <Typography variant='h3' className='text-primary tracking-wider font-secondary font-medium'>
                                    LA SIGNATURE OLFACTIVE
                                </Typography>
                                <Typography variant='paragraph' className='text-info text-justify mt-10'>
                                    Nous nous référons à une combinaison
                                    unique de parfums soigneusement
                                    sélectionnés pour représenter l'identité de
                                    votre entreprise, c'est votre empreinte
                                    olfactive distinctive qui permet aux prospects
                                    de reconnaître immédiatement votre
                                    marque.
                                    <br></br>
                                    <br></br>
                                    En adoptant une signature olfactive, une
                                    entreprise cherche à différencier sa marque
                                    de manière mémorable, à renforcer son
                                    identité et à créer une expérience sensorielle
                                    distinctive pour les clients.
                                </Typography>
                            </div>


                            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                                <div className="col-span-1">
                                    <div className='w-full h-64 sm:h-full bg-cover bg-center' style={{ backgroundImage: `url(${grid1})` }}></div>
                                </div>

                                <div className="col-span-1 grid grid-rows-1 sm:grid-rows-2 gap-0 sm:gap-4">
                                    <div className='w-full h-64 sm:h-full bg-cover bg-center' style={{ backgroundImage: `url(${grid2})` }}></div>
                                    <div className='w-full h-64 sm:h-full bg-cover bg-center mt-4 sm:mt-0' style={{ backgroundImage: `url(${grid3})` }}></div>
                                </div>
                            </div>
                        </div>
                    </motion.section>
                </div>

                <div ref={refContact}>
                    <motion.section
                        variants={{
                            hidden: { opacity: 0, y: 75 },
                            visible: { opacity: 1, y: 0 }
                        }}
                        initial='hidden'
                        animate={contactControls}
                        transition={{ duration: 0.5, delay: 0.25 }}
                        className="mt-20 relative">
                        <div className='bg-[#C9BAAD]'>
                            <div className='container mx-auto flex flex-col items-center pt-10 pb-52'>
                                <Typography className='text-white tracking-wider font-secondary sm:text-3xl text-xl font-normal text-center'>
                                    VOUS DESIREZ, NOUS REALISONS
                                </Typography>

                                <Typography className='text-white text-center'>
                                    une période d’essai est offerte pour admirer l’efficacité de nos produits
                                </Typography>
                            </div>
                        </div>


                        <form ref={form} onSubmit={contactHandler} className='container mx-auto sm:w-2/3 bg-white sm:border sm:border-secondary p-10 -mt-40 mb-20'>
                            <div className="grid md:grid-cols-2 grid-cols-1 gap-4 mt-4 text-info">
                                <div className='flex flex-col'>
                                    <Typography
                                        className='ml-1'
                                        variant='paragraph'
                                    >
                                        Nom *
                                    </Typography>
                                    <input type='text' placeholder='Nom' id='lastname' name='lastname' onChange={(e) => setLastname(e.target.value)} className={`px-2 py-1 border placeholder:font-light ${isLastnameEmpty ? 'border-red-400' : 'border-gray-400'}`} />
                                </div>
                                <div className='flex flex-col'>
                                    <Typography
                                        className='ml-1'
                                        variant='paragraph'
                                    >
                                        Prénom *
                                    </Typography>
                                    <input type='text' placeholder='Prénom' id='firstname' name='firstname' onChange={(e) => setFirstname(e.target.value)} className={`px-2 py-1 border placeholder:font-light ${isFirstnameEmpty ? 'border-red-400' : 'border-gray-400'}`} />
                                </div>

                                <div className='flex flex-col'>
                                    <Typography
                                        className='ml-1'
                                        variant='paragraph'
                                    >
                                        Adresse email *
                                    </Typography>
                                    <input type='email' id='email' name='email' placeholder='Adresse email' onChange={(e) => setEmail(e.target.value)} className={`px-2 py-1 border placeholder:font-light ${isEmailEmpty ? 'border-red-400' : 'border-gray-400'}`} />
                                </div>
                                <div className='flex flex-col'>
                                    <Typography
                                        className='ml-1'
                                        variant='paragraph'
                                    >
                                        Numéro de téléphone *
                                    </Typography>
                                    <input type='text' id='phone' name='phone' placeholder='Numéro de téléphone' onChange={(e) => setPhone(e.target.value)} className={`px-2 py-1 border placeholder:font-light ${isPhoneEmpty ? 'border-red-400' : 'border-gray-400'}`} />
                                </div>

                                <div className='flex flex-col'>
                                    <Typography
                                        className='ml-1'
                                        variant='paragraph'
                                    >
                                        Nom de l'entreprise *
                                    </Typography>
                                    <input type='text' id='company' name='company' placeholder="Nom de l'entreprise" onChange={(e) => setCompany(e.target.value)} className={`px-2 py-1 border placeholder:font-light ${isCompanyEmpty ? 'border-red-400' : 'border-gray-400'}`} />
                                </div>

                                <div className='flex flex-col'>
                                    <Typography
                                        className='ml-1'
                                        variant='paragraph'
                                    >
                                        Wilaya *
                                    </Typography>
                                    <select id='wilaya' name='wilaya' onChange={(e) => setWilaya(e.target.value)} className={`px-2 py-1 border placeholder:font-light bg-white ${isWilayaEmpty ? 'border-red-400' : 'border-gray-400'}`}>
                                        {wilayas.map((wilaya) => (
                                            <option key={wilaya.id} value={wilaya.name}>{wilaya.code} - {wilaya.name}</option>
                                        ))}
                                    </select>
                                </div>

                                <div className='flex flex-col md:col-span-2 col-span-1'>
                                    <Typography
                                        className='ml-1'
                                        variant='paragraph'
                                    >
                                        Votre message *
                                    </Typography>
                                    <textarea type='text' id='message' name='message' placeholder='Votre message ...' onChange={(e) => setMessage(e.target.value)} className={`px-2 py-1 h-36 border placeholder:font-light ${isMessageEmpty ? 'border-red-400' : 'border-gray-400'}`} />
                                </div>

                            </div>

                            <div className='flex justify-center w-full'>
                                <button
                                    className="bg-secondary p-3 mt-5 mb-5 text-info uppercase font-medium"
                                >
                                    Envoyer
                                </button>
                            </div>
                        </form>

                    </motion.section>
                </div>

            </div>
        </div>
    )
}

export default Premium