import React from "react";
import { Route, Routes } from "react-router-dom";

import Layout from "./layouts/layout/Layout";
import Dashboard from "./layouts/dashboard/Dashboard";

import PrivateRoute from "./utils/PrivateRoute";
import PublicRoute from "./utils/PublicRoute";

import Home from "./pages/auth/Home";
import Login from "./pages/auth/Login";
import ProductDetails from "./pages/auth/ProductDetails";

import Statistics from "./pages/dashboard/Statistics";
import Products from "./pages/dashboard/Products";
import Orders from "./pages/dashboard/Orders";
import PlaceOrder from "./pages/auth/PlaceOrder";
import ShoppingCart from "./pages/auth/ShoppingCart";
import Users from "./pages/dashboard/Users";
import Contact from "./pages/auth/Contact";
import Shop from "./pages/auth/Shop";
import Categories from "./pages/dashboard/Categories";
import Premium from "./pages/auth/Premium";
import Business from "./pages/auth/Business";
import SentHome from "./pages/auth/SentHome";

import Diffuser from "./pages/auth/Diffuser";
import Parfum from "./pages/auth/Parfum";
import CandleReed from "./pages/auth/CandleReed";




const App = () => {
  return (

    <Routes>
      <Route element={<PublicRoute />}>
        <Route path="/" element={<Layout />}>
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<Home />} />
          <Route path="/product/:id" element={<ProductDetails />} />
          <Route path="/place-order" element={<PlaceOrder />} />
          <Route path="/shopping-cart" element={<ShoppingCart />} />
          <Route path="/shop" element={<Shop />} />

          <Route path="/diffuser" element={<Diffuser />} />
          <Route path="/parfum" element={<Parfum />} />
          <Route path="/candle-reed" element={<CandleReed />} />

          <Route path="/contact" element={<Contact />} />

          <Route path="/premium" element={<Premium />} />
          <Route path="/business" element={<Business />} />
          <Route path="/homescent" element={<SentHome />} />
        </Route>
      </Route>

      <Route element={<PrivateRoute />}>
        <Route path="/dashboard" element={<Dashboard />}>
          <Route index element={<Statistics />} />
          <Route path="produits" element={<Products />} />
          <Route path="categories" element={<Categories />} />
          <Route path="commandes" element={<Orders />} />
          <Route path="utilisateurs" element={<Users />} />
        </Route>
      </Route>
    </Routes>
  );
};

export default App;


// <div className="overflow-hidden">
{/* <Router>
        <Header />
        <Routes>
          <Route path="/login" element={<Login />}></Route>
          <Route path="/" element={<Home />}></Route>
          <Route path="/product/:id" element={<ProductDetails />}></Route>
        </Routes>
        <Sidebar />
        <Footer />
      </Router> */}
// </div>