import React, { useContext, useEffect, useState } from "react";
import {
    Button,
    Dialog,
    Card,
    CardBody,
    CardFooter,
    Typography,
    Spinner,
    Avatar,
    Chip,
} from "@material-tailwind/react";

import { format } from 'date-fns'
import { fr } from 'date-fns/locale'

import { API_LINK } from "../../../apiConfig";
import axios from "axios";
import { AuthContext } from "../../../contexts/AuthContext";

import profile from "../../../img/profile.jpg";

const TABLE_HEAD = ["Produit", "Prix", "Quantité", "Total"];

export const ShowOrder = ({ open, handleOpen, selectedOrder, setSelectedOrder }) => {
    const { authTokens } = useContext(AuthContext);
    const [order, setOrder] = useState();
    const [loading, setLoading] = useState(false);

    const [listWilayas, setListWilayas] = useState([]);

    useEffect(() => {
        axios.get(`${API_LINK}/wilayas`).then(({ data }) => {
            setListWilayas(data);
        }).catch((error) => {
            console.log(error);
        })
    }, [])


    useEffect(() => {
        if (open && selectedOrder) {
            setLoading(true);
            axios.get(`${API_LINK}/orders/${selectedOrder}`, {
                headers: {
                    Authorization: `Bearer ${authTokens?.accessToken}`
                }
            }).then(({ data }) => {
                setOrder(data)
            }).catch((error) => {
                console.log(error);
            }).finally(() => {
                setLoading(false);
            });
        }

    }, [open, selectedOrder])

    const closeModalHandler = () => {
        handleOpen();
        setSelectedOrder(null);
    }

    const showDeliveryPrice = (wilaya, deliveryType) => {
        const selectedWilaya = listWilayas.find(item => item.destination === wilaya);
        if (selectedWilaya) {
            if (deliveryType) {
                if (deliveryType === 'economical') {
                    return selectedWilaya.economical;
                } else if (deliveryType === 'express') {
                    return selectedWilaya.express
                }
            } else {
                return 0;
            }

        } else {
            return 0;
        }
    };

    const calculateTotalPriceProducts = () => {
        let total = 0;
        order?.orderDetails.forEach((orderDetail) => {
            // Ajouter le montant total pour chaque produit
            total += orderDetail.unitPrice * orderDetail.quantity;
        });
        return total;
    };

    return (
        <>
            <Dialog
                size="md"
                open={open}
                handler={closeModalHandler}
                className="bg-transparent shadow-none"
            >
                {!loading ? (<Card className="mx-auto w-full">
                    <CardBody className="flex flex-col gap-4">
                        <Typography variant="h4" color="blue-gray">
                            Détail de la commande
                        </Typography>
                        {order && <div className="w-full flex flex-col">
                            <div className="grid md:grid-cols-2 grid-cols-1 gap-4 w-full">
                                <div className="flex flex-row items-center">
                                    <Avatar
                                        src={profile}
                                        alt="avatar"
                                        size="xl"
                                        variant="rounded"
                                        className="p-0.5"
                                    />
                                    <div className="ml-4">
                                        <Typography variant="lead" color="blue-gray" className="capitalize">
                                            {order?.customerName}
                                        </Typography>
                                        <Typography variant="paragraph" color="blue-gray">
                                            {order?.customerPhone}
                                        </Typography>
                                    </div>
                                </div>
                                <div className="flex flex-col items-end justify-center">
                                    <Chip
                                        size="sm"
                                        variant="ghost"
                                        className="w-min mb-2"
                                        value={order?.status === 'pending' ? 'En attente' : order?.status === 'processing' ? 'En traitement' : order?.status === 'shipped' ? 'Expédiée' : order?.status === 'delivered' ? 'Livrée' : order?.status === 'canceled' && 'Annulée'}
                                        color={
                                            order?.status === "delivered" ? "green" : order?.status === "pending" ? "amber" : order?.status === "processing" ? "purple" : order?.status === "canceled" ? "red" : order?.status === "shipped" && "blue"
                                        }
                                    />
                                    <Typography>
                                        {format(new Date(order?.orderDate), 'dd MMMM yyyy', { locale: fr })}
                                    </Typography>
                                </div>
                            </div>

                            <div className="flex flex-row items-center pt-4">
                                <Typography variant="paragraph" color="blue-gray" className="capitalize font-medium">
                                    Livraison
                                </Typography>
                                <div className="w-full ml-4">
                                    <Typography variant="paragraph" color="blue-gray">
                                        {order?.shippingWilaya} - {order?.shippingType === 'economical' ? "Économique" : "Express"}
                                    </Typography>
                                </div>
                            </div>

                            <div className="flex flex-row items-center pb-4">
                                <Typography variant="paragraph" color="blue-gray" className="capitalize font-medium">
                                    Adresse
                                </Typography>
                                <div className="w-full ml-4">
                                    <Typography variant="paragraph" color="blue-gray">
                                        {order?.shippingAddress}
                                    </Typography>
                                </div>
                            </div>

                            <div className="py-4 overflow-x-scroll">
                                <table className="w-full min-w-max table-auto">
                                    <thead>
                                        <tr>
                                            {TABLE_HEAD.map((head) => (
                                                <th
                                                    key={head}
                                                    className="border-y border-gray-400 p-4"
                                                >
                                                    <Typography
                                                        variant="paragraph"
                                                        className="leading-none text-info font-medium"
                                                    >
                                                        {head}
                                                    </Typography>
                                                </th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {order?.orderDetails.map((orderDetail) => {
                                            const classes = "p-4 border-b border-gray-400";
                                            return (
                                                <tr key={orderDetail.id} className="text-primary">
                                                    <td className={classes}>
                                                        <div className="flex items-center gap-3">
                                                            <img src={`${API_LINK}/${orderDetail.product?.images[0]}`} alt={orderDetail.product?.name} className='w-16 h-16' />
                                                            <Typography
                                                                variant="paragraph"
                                                                color="blue-gray"
                                                                className="capitalize"
                                                            >
                                                                {orderDetail.product?.name}
                                                            </Typography>
                                                        </div>
                                                    </td>

                                                    <td className={classes}>
                                                        <div className="flex items-center gap-3">
                                                            <Typography
                                                                variant="paragraph"
                                                                color="blue-gray"
                                                                className="capitalize"
                                                            >
                                                                {orderDetail?.unitPrice} DZD
                                                            </Typography>
                                                        </div>
                                                    </td>

                                                    <td className={classes}>
                                                        <div className="flex items-center gap-3">
                                                            <Typography
                                                                variant="paragraph"
                                                                color="blue-gray"
                                                                className="capitalize font-semibold"
                                                            >
                                                                {orderDetail?.quantity}
                                                            </Typography>
                                                        </div>
                                                    </td>

                                                    <td className={classes}>
                                                        <div className="flex items-center gap-3">
                                                            <Typography
                                                                variant="paragraph"
                                                                color="blue-gray"
                                                                className="capitalize"
                                                            >
                                                                {orderDetail?.unitPrice * orderDetail?.quantity} DZD
                                                            </Typography>
                                                        </div>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                                <div className="flex items-center justify-between">
                                    <div className="flex flex-row items-center p-4">
                                        <Typography variant="paragraph" color="blue-gray" className="capitalize font-medium">
                                            Expédition
                                        </Typography>
                                        <div className="w-full ml-4">
                                            <Typography variant="paragraph" color="blue-gray">
                                                {showDeliveryPrice(order?.shippingWilaya, order?.shippingType)} DZD
                                            </Typography>
                                        </div>
                                    </div>

                                    <div className="flex flex-row items-center p-4">
                                        <Typography variant="paragraph" color="blue-gray" className="capitalize font-medium">
                                            Total
                                        </Typography>
                                        <div className="w-full ml-4">
                                            <Typography variant="paragraph" color="blue-gray">
                                                {calculateTotalPriceProducts() + showDeliveryPrice(order?.shippingWilaya, order?.shippingType)} DZD
                                            </Typography>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>}
                    </CardBody>
                    <CardFooter className="pt-0">
                        <Button variant="filled" className="shadow-none bg-[#E1B6A5]" onClick={closeModalHandler} fullWidth>
                            D'accord
                        </Button>
                    </CardFooter>
                </Card>
                ) : (
                    <div className="flex justify-center items-center p-5">
                        <Spinner />
                    </div>
                )}
            </Dialog>
        </>
    );
}