import { Typography } from "@material-tailwind/react";
import React from "react";
import { FaShippingFast, FaHandHoldingUsd, FaCommentDots } from "react-icons/fa";
import { FiPhoneCall, FiInstagram, FiLinkedin, FiMail } from "react-icons/fi";

import Logo from "../../img/logofooter.png";
import { Link } from "react-router-dom";



const Footer = () => {
    return (
        <footer className="bg-[#F8F5F3] pt-8 pb-4 text-primary">
            <div className="container mx-auto">


                <div className="grid sm:grid-cols-2 md:grid-cols-6 grid-cols-1 gap-4 py-8">
                    <Link to={"/"} className="flex items-center pr-4 md:col-span-2 col-span-1">
                        <img src={Logo} alt="logo footer" className="h-20" />
                    </Link>
                    <div className="md:col-span-4 grid md:grid-cols-3 grid-cols-1">
                        <div className="flex flex-row justify-start items-center sm:mb-0 mb-4">
                            <FaShippingFast size={36} />
                            <div className="ml-4">
                                <Typography className="text-sm font-semibold">
                                    Livraison 58 wilayas.
                                </Typography>
                                <Typography className="text-sm font-normal">
                                    Livraison 24 - 72h.
                                </Typography>
                            </div>
                        </div>

                        <div className="flex flex-row justify-start items-center sm:mb-0 mb-4">
                            <FaCommentDots size={36} />
                            <div className="ml-4">
                                <Typography className="text-sm font-semibold">
                                    Support disponible.
                                </Typography>
                                <Typography className="text-sm font-normal">
                                    Support 24h/7j.
                                </Typography>
                            </div>
                        </div>

                        <div className="flex flex-row justify-start items-center sm:mb-0 mb-4">
                            <FaHandHoldingUsd size={36} />
                            <div className="ml-4">
                                <Typography className="text-sm font-semibold">
                                    Payment à la livraison.
                                </Typography>
                                <Typography className="text-sm font-normal">
                                    Payer à la livraison
                                </Typography>
                            </div>
                        </div>
                    </div>
                </div>



                <div className="grid sm:grid-cols-4 md:grid-cols-6 grid-cols-1 gap-4 pb-8">


                    <div className="flex flex-col items-start sm:col-span-2 md:col-span-2 col-span-1 sm:mb-0 mb-4">
                        <div className="flex items-center mb-1">
                            <FiPhoneCall className="mr-3 text-lg" />
                            <span className="font-normal text-sm"> 0560 865 463</span>
                        </div>
                        <div className="flex items-center mb-1">
                            <FiMail className="mr-3 text-lg" />
                            <span className="font-normal text-sm">contact@thescentdigital.com</span>
                        </div>
                        <a className="flex items-center mb-1" href="https://www.instagram.com/thescentdigital?igsh=Nm50M25najl2OXRt" target="_blank">
                            <FiInstagram className="mr-3 text-lg" />
                            <span className="font-normal text-sm">thescentdigital</span>
                        </a>
                        <div className="flex items-center">
                            <FiLinkedin className="mr-3 text-lg" />
                            <span className="font-normal text-sm">THE SCENT DIGITAL</span>
                        </div>
                    </div>


                    <div className="flex flex-col sm:col-span-2  md:col-span-1 col-span-1 items-start sm:mb-0 mb-4">
                        <Typography variant="paragraph" className="font-medium mb-2">
                            À PROPOS
                        </Typography>

                        <Link to={`/`}>
                            <Typography
                                className={`font-normal text-sm`}
                            >
                                Accueil
                            </Typography>
                        </Link>

                        <Link to={`/premium`}>
                            <Typography
                                className={`font-normal text-sm`}
                            >
                                Premium
                            </Typography>
                        </Link>

                        <Link to={`/business`}>
                            <Typography
                                className={`font-normal text-sm`}
                            >
                                Business
                            </Typography>
                        </Link>

                        <Link to={`/homescent`}>
                            <Typography
                                className={`font-normal text-sm`}
                            >
                                HomeScent
                            </Typography>
                        </Link>
                    </div>


                    <div className="flex flex-col items-start sm:col-span-2  md:col-span-1 col-span-1 sm:mb-0 mb-4">
                        <Typography variant="paragraph" className="font-medium mb-2">
                            SHOP
                        </Typography>

                        <Link to={`/diffuser`}>
                            <Typography
                                className={`font-normal text-sm`}
                            >
                                Diffuseurs
                            </Typography>
                        </Link>

                        <Link to={`/parfum`}>
                            <Typography
                                className={`font-normal text-sm`}
                            >
                                Parfums
                            </Typography>
                        </Link>

                        <Link to={`/candle-reed`}>
                            <Typography
                                className={`font-normal text-sm`}
                            >
                                Candles & Reed
                            </Typography>
                        </Link>

                    </div>


                    <div className="flex flex-col items-start sm:col-span-2  md:col-span-2 col-span-1 sm:mb-0 mb-4">
                        <Typography variant="paragraph" className="font-medium mb-2">
                            NEWSLETTER
                        </Typography>

                        <Typography
                            className={`font-normal text-sm mb-2`}
                        >
                            Abonnez-vous pour recevoir des offres uniques.
                        </Typography>

                        <div className="w-full flex items-center border border-primary bg-white">
                            <input type='email' placeholder='E-mail' className={`px-2 py-1 flex-1 placeholder:font-light  placeholder:text-primary`} />
                            <button className="bg-transparent px-2 py-[5px] text-primary">
                                Souscrire
                            </button>
                        </div>

                    </div>


                </div>

                <hr className="my-3 border-primary" />
                <p className="text-center">
                    Copyright &copy; The scent digital 2024. Tous droits réservés.
                </p>
            </div>
        </footer>
    );
};

export default Footer;
