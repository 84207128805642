import React, { useEffect, useRef, useState } from 'react';
import { motion, useInView, useAnimation } from 'framer-motion'
import { Tooltip, Typography } from '@material-tailwind/react';
import emailjs from '@emailjs/browser'

import wilayas from '../../data/wilayas.json'

import banner from '../../img/business/banner.png';

import grid1 from '../../img/business/grid1.png';
import grid2 from '../../img/business/grid2.png';
import grid3 from '../../img/business/grid3.png';
import grid4 from '../../img/business/grid4.png';
import grid5 from '../../img/business/grid5.png';

import icon1 from '../../img/business/Business Icon/1.png';
import icon2 from '../../img/business/Business Icon/2.png';
import icon3 from '../../img/business/Business Icon/3.png';
import icon4 from '../../img/business/Business Icon/4.png';
import icon5 from '../../img/business/Business Icon/5.png';

import serviceclient from '../../img/business/service-clients.png';

const Business = () => {
    const bannerControls = useAnimation()
    const refBanner = useRef(null);
    const isInViewBanner = useInView(refBanner);

    const galleryControls = useAnimation()
    const refGallery = useRef(null);
    const isInViewGallery = useInView(refGallery);

    const infoControls = useAnimation()
    const refInfo = useRef(null);
    const isInViewInfo = useInView(refInfo);

    const contactControls = useAnimation()
    const refContact = useRef(null);
    const isInViewContact = useInView(refContact);


    useEffect(() => {
        if (isInViewBanner) {
            bannerControls.start("visible")
        }

        if (isInViewGallery) {
            galleryControls.start("visible")
        }

        if (isInViewInfo) {
            infoControls.start("visible")
        }

        if (isInViewContact) {
            contactControls.start("visible")
        }
    }, [isInViewBanner, isInViewGallery, isInViewInfo, isInViewContact])



    const form = useRef();
    const [isActive, setIsActive] = useState(false);

    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [company, setCompany] = useState('');
    const [wilaya, setWilaya] = useState('');
    const [message, setMessage] = useState('');

    const [isFirstnameEmpty, setIsFirstnameEmpty] = useState(false);
    const [isLastnameEmpty, setIsLastnameEmpty] = useState(false);
    const [isPhoneEmpty, setIsPhoneEmpty] = useState(false);
    const [isEmailEmpty, setIsEmailEmpty] = useState(false);
    const [isCompanyEmpty, setIsCompanyEmpty] = useState(false);
    const [isWilayaEmpty, setIsWilayaEmpty] = useState(false);
    const [isMessageEmpty, setIsMessageEmpty] = useState(false);

    const validateInputs = () => {
        setIsFirstnameEmpty(firstname.trim() === '');
        setIsLastnameEmpty(lastname.trim() === '');
        setIsPhoneEmpty(phone.trim() === '');
        setIsEmailEmpty(email.trim() === '');
        setIsCompanyEmpty(company.trim() === '');
        setIsWilayaEmpty(wilaya.trim() === '');
        setIsMessageEmpty(message.trim() === '');
        return (
            firstname.trim() !== '' &&
            lastname.trim() !== '' &&
            phone.trim() !== '' &&
            email.trim() !== '' &&
            company.trim() !== '' &&
            wilaya.trim() !== '' &&
            message.trim() !== ''
        );
    };


    const contactHandler = async (e) => {
        e.preventDefault();
        if (validateInputs()) {
            emailjs.sendForm('service_ghatcog', 'template_2uvv0kr', form.current, '_gU1vBHGU3VO8PBw9')
                .then((result) => {
                    console.log(result.text);
                    defaultValuesForInputs();
                }, (error) => {
                    console.log(error.text);
                });

        } else {
            alert('Veuillez remplir tous les champs')
        }
        e.target.reset();
    }

    const defaultValuesForInputs = () => {
        setFirstname('');
        setLastname('');
        setEmail('');
        setPhone('');
        setCompany('');
        setWilaya('');
        setMessage('');
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        window.addEventListener("scroll", () => {
            window.scrollY > 60 ? setIsActive(true) : setIsActive(false);
        });
    });

    return (
        <div>
            <div
                className={`${isActive ? 'h-[72px]' : 'h-[88px]'} top-0 bg-white fixed w-full transition-all`}
            ></div>

            {/* <div className='sm:mt-[110px] mt-[96px] text-info'> */}
            <div className='sm:mt-[80px] mt-[60px] text-info'>


                <div ref={refBanner}>
                    <motion.section
                        variants={{
                            hidden: { opacity: 0, y: 75 },
                            visible: { opacity: 1, y: 0 }
                        }}
                        initial='hidden'
                        animate={bannerControls}
                        transition={{ duration: 0.5, delay: 0.25 }}
                        className='relative'
                    >
                        <img src={banner} className="w-full h-screen object-cover" />
                        <div className='absolute inset-0 sm:w-1/2 sm:flex hidden justify-center items-center'>
                            <div className='flex flex-col items-center'>
                                <Typography className='flex flex-col items-center text-white sm:text-4xl text-2xl font-medium font-secondary text-center'>
                                    <span className='tracking-widest mb-4'>SUBLIMEZ</span>
                                    <span className='tracking-widest mb-4'>VOS ESPACES</span>
                                </Typography>
                                <Typography className='flex flex-col items-center text-white sm:text-2xl text-xl font-medium font-secondary text-center'>
                                    AVEC NOS DIFFUSEURS DE PARFUM
                                </Typography>
                            </div>
                        </div>
                    </motion.section>
                </div>

                <section className="mt-10">
                    <hr className="w-1/2 border-1 border-primary"></hr>
                    <div className="container mx-auto sm:w-2/3 py-10">
                        <Typography variant='h5' className='text-info text-center font-light'>
                            Le pouvoir du souvenir olfactif est indéniablement fort, surpassant même la mémoire visuelle ou sonore.
                        </Typography>

                        <Typography variant='h4' className='text-[#87604E] font-secondary tracking-widest text-center font-medium mt-4'>
                            PLONGEZ DANS L’EXPERIENCE PERSONNALISÉE DU PARFUM
                            D’AMBIANCE AVEC THE SCENT DIGITAL
                        </Typography>
                    </div>
                    <hr className="w-1/2 border-1 border-primary float-right"></hr>
                </section>

                <div ref={refGallery}>
                    <motion.section
                        variants={{
                            hidden: { opacity: 0, y: 75 },
                            visible: { opacity: 1, y: 0 }
                        }}
                        initial='hidden'
                        animate={galleryControls}
                        transition={{ duration: 0.5, delay: 0.25 }}
                        className="mt-10"
                    >
                        <div className="container mx-auto grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
                            <div className="col-span-1">
                                <div className='w-full h-64 sm:h-full bg-secondary sm:relative'>
                                    {/* <img src={grid1} className='sm:absolute sm:top-0 sm:-right-3' /> */}
                                    <div className='w-full h-full sm:h-3/4 bg-cover bg-center sm:absolute sm:top-0 sm:-right-3' style={{ backgroundImage: `url(${grid1})` }}></div>
                                </div>
                            </div>

                            <div className="col-span-1 grid grid-rows-1 sm:grid-rows-2 gap-0 sm:gap-6">
                                <div className='w-full h-64 flex items-center'>
                                    <Typography variant='paragraph' className='text-info text-justify'>
                                        Hotels, banque, showroom,
                                        spa, ou clinique… Quel que soit
                                        votre secteur d’activité, nous
                                        avons la solution pour sublimer
                                        vos espaces avec des senteurs
                                        divines.
                                    </Typography>
                                </div>
                                <div className='w-full h-64 sm:h-full bg-cover bg-center  mt-6 sm:mt-0' style={{ backgroundImage: `url(${grid2})` }}></div>
                            </div>


                            <div className="col-span-1">
                                <div className='w-full h-64 sm:h-full bg-secondary sm:relative'>
                                    {/* <img src={grid3} className='sm:h-3/4' /> */}
                                    <div className='w-full h-full bg-cover bg-center sm:h-3/4 sm:absolute sm:bottom-6' style={{ backgroundImage: `url(${grid3})` }}></div>
                                </div>
                            </div>

                            <div className="col-span-1 grid grid-rows-1 sm:grid-rows-2 gap-0 sm:gap-6">
                                <div className='w-full h-64 sm:h-full bg-cover bg-center' style={{ backgroundImage: `url(${grid4})` }}></div>
                                <div className='w-full h-64 sm:h-full bg-cover mt-6 sm:mt-0' style={{ backgroundImage: `url(${grid5})` }}></div>
                            </div>

                        </div>
                    </motion.section>
                </div>


                <div ref={refInfo}>
                    <motion.section
                        variants={{
                            hidden: { opacity: 0, y: 75 },
                            visible: { opacity: 1, y: 0 }
                        }}
                        initial='hidden'
                        animate={infoControls}
                        transition={{ duration: 0.5, delay: 0.25 }}
                        className="mt-10"
                    >
                        <div className="container mx-auto sm:w-2/3 py-10">
                            <Typography variant='h4' className='text-info text-center font-secondary tracking-wider font-semibold'>
                                DÉVELOPPÉS EN ALGÉRIE
                            </Typography>

                            <Typography variant='h4' className='text-info text-center font-secondary tracking-wider font-normal mt-4'>
                                POUR VOUS ASSURER UNE DISPONIBILITÉ SANS FAILLE
                            </Typography>

                            <div className='flex flex-row flex-wrap items-center justify-center gap-5 mt-10'>
                                <div className='flex justify-center h-36 w-36'>
                                    <img src={icon1} className='h-full' />
                                </div>

                                <div className='flex justify-center h-36 w-36'>
                                    <img src={icon2} className='h-full' />
                                </div>

                                <div className='flex justify-center h-36 w-36'>
                                    <img src={icon3} className='h-full' />
                                </div>

                                <div className='flex justify-center h-36 w-36'>
                                    <img src={icon4} className='h-full' />
                                </div>

                                <div className='flex justify-center h-36 w-36'>
                                    <img src={icon5} className='h-full' />
                                </div>

                            </div>
                        </div>
                    </motion.section>
                </div>


                <section className="pt-20 bg-[#C9BAAD] mt-32">
                    <div className="container mx-auto sm:w-2/3 relative">


                        <Tooltip className="" content={<Typography variant='h4' className='text-primary text-center font-normal'>
                            0560 865 463
                        </Typography>}>
                            <div className='absolute -top-48 left-0 right-0 mx-auto flex justify-center'>
                                <div className='bg-white rounded-full p-4 '>
                                    <img src={serviceclient} className='w-44 h-44' />
                                </div>
                            </div>
                        </Tooltip>

                        <div className='py-10'>
                            <Typography variant='h4' className='text-white text-center font-secondary tracking-wider font-normal'>
                                Un commercial sera à votre écoute pour le choix des diffuseurs et
                                du parfum selon votre secteur d’activité
                            </Typography>
                        </div>
                    </div>

                </section>


                <div ref={refContact}>
                    <motion.section
                        variants={{
                            hidden: { opacity: 0, y: 75 },
                            visible: { opacity: 1, y: 0 }
                        }}
                        initial='hidden'
                        animate={contactControls}
                        transition={{ duration: 0.5, delay: 0.25 }}
                        className="relative"
                    >
                        <div className='bg-[#F8F5F3]'>
                            <div className='container mx-auto flex flex-col items-center pt-16 pb-52'>
                                <Typography className='text-primary font-secondary tracking-wider sm:text-3xl text-xl font-normal text-center'>
                                    FAITES VOTRE DEVIS
                                </Typography>
                            </div>
                        </div>


                        <form ref={form} onSubmit={contactHandler} className='container mx-auto sm:w-2/3 bg-white sm:border sm:border-secondary p-10 -mt-40 mb-20'>
                            <div className="grid md:grid-cols-2 grid-cols-1 gap-4 mt-4 text-info">
                                <div className='flex flex-col'>
                                    <Typography
                                        className='ml-1'
                                        variant='paragraph'
                                    >
                                        Nom *
                                    </Typography>
                                    <input type='text' placeholder='Nom' id='lastname' name='lastname' onChange={(e) => setLastname(e.target.value)} className={`px-2 py-1 border placeholder:font-light ${isLastnameEmpty ? 'border-red-400' : 'border-gray-400'}`} />
                                </div>
                                <div className='flex flex-col'>
                                    <Typography
                                        className='ml-1'
                                        variant='paragraph'
                                    >
                                        Prénom *
                                    </Typography>
                                    <input type='text' placeholder='Prénom' id='firstname' name='firstname' onChange={(e) => setFirstname(e.target.value)} className={`px-2 py-1 border placeholder:font-light ${isFirstnameEmpty ? 'border-red-400' : 'border-gray-400'}`} />
                                </div>

                                <div className='flex flex-col'>
                                    <Typography
                                        className='ml-1'
                                        variant='paragraph'
                                    >
                                        Adresse email *
                                    </Typography>
                                    <input type='email' placeholder='Adresse email' id='email' name='email' onChange={(e) => setEmail(e.target.value)} className={`px-2 py-1 border placeholder:font-light ${isEmailEmpty ? 'border-red-400' : 'border-gray-400'}`} />
                                </div>
                                <div className='flex flex-col'>
                                    <Typography
                                        className='ml-1'
                                        variant='paragraph'
                                    >
                                        Numéro de téléphone *
                                    </Typography>
                                    <input type='text' placeholder='Numéro de téléphone' id='phone' name='phone' onChange={(e) => setPhone(e.target.value)} className={`px-2 py-1 border placeholder:font-light ${isPhoneEmpty ? 'border-red-400' : 'border-gray-400'}`} />
                                </div>

                                <div className='flex flex-col'>
                                    <Typography
                                        className='ml-1'
                                        variant='paragraph'
                                    >
                                        Nom de l'entreprise *
                                    </Typography>
                                    <input type='text' placeholder="Nom de l'entreprise" id='company' name='company' onChange={(e) => setCompany(e.target.value)} className={`px-2 py-1 border placeholder:font-light ${isCompanyEmpty ? 'border-red-400' : 'border-gray-400'}`} />
                                </div>


                                <div className='flex flex-col'>
                                    <Typography
                                        className='ml-1'
                                        variant='paragraph'
                                    >
                                        Wilaya *
                                    </Typography>
                                    <select onChange={(e) => setWilaya(e.target.value)} id='wilaya' name='wilaya' className={`px-2 py-1 border placeholder:font-light bg-white ${isWilayaEmpty ? 'border-red-400' : 'border-gray-400'}`}>
                                        {wilayas.map((wilaya) => (
                                            <option key={wilaya.id} value={wilaya.name}>{wilaya.code} - {wilaya.name}</option>
                                        ))}
                                    </select>
                                </div>

                                <div className='flex flex-col md:col-span-2 col-span-1'>
                                    <Typography
                                        className='ml-1'
                                        variant='paragraph'
                                    >
                                        Votre message *
                                    </Typography>
                                    <textarea type='text' placeholder='Votre message ...' id='message' name='message' onChange={(e) => setMessage(e.target.value)} className={`px-2 py-1 h-36 border placeholder:font-light ${isMessageEmpty ? 'border-red-400' : 'border-gray-400'}`} />
                                </div>

                            </div>
                            <div className='flex justify-center w-full'>
                                <button
                                    className="bg-secondary p-3 mt-5 mb-5 text-info uppercase font-medium"
                                >
                                    Envoyer
                                </button>
                            </div>
                        </form>

                    </motion.section>
                </div>

            </div>
        </div>
    )
}

export default Business