import React, { useContext, useEffect, useState } from "react";
import {
    Button,
    Dialog,
    Card,
    CardBody,
    CardFooter,
    Typography,
    Input,
    // Textarea,
    Select,
    Option,
    Spinner,
    IconButton,
} from "@material-tailwind/react";
import ReactQuill from 'react-quill';

import 'react-quill/dist/quill.snow.css';
import "react-quill/dist/quill.core.css";

import ImageUploading from 'react-images-uploading';

import { PhotoIcon } from "@heroicons/react/24/outline";

import { API_LINK } from "../../../apiConfig";
import axios from "axios";
import { AuthContext } from "../../../contexts/AuthContext";
import { BsPencil, BsTrash, BsX } from "react-icons/bs";

export const AddUpdateProduct = ({ open, handleOpen, setProducts, selectedProduct, setSelectedProduct }) => {
    const { authTokens } = useContext(AuthContext);
    const [loading, setLoading] = useState(false);

    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [stockQuantity, setStockQuantity] = useState('');
    const [price, setPrice] = useState('');
    const [category, setCategory] = useState('');

    const [type, setType] = useState('');
    const [specification, setSpecification] = useState('');
    const [color, setColor] = useState('');

    const [images, setImages] = useState([]);
    const [video, setVideo] = useState('');



    useEffect(() => {
        setLoading(true);
        if (open) {
            setLoading(false);

            if (selectedProduct) {
                setName(selectedProduct.name || '');
                setDescription(selectedProduct.description || '');
                setStockQuantity(selectedProduct.stockQuantity || '');
                setPrice(selectedProduct.price || '');
                setCategory(selectedProduct.category || '');
                setType(selectedProduct.type || '');
                setSpecification(selectedProduct.specification || '');
                setColor(selectedProduct.color || '');
                setVideo(selectedProduct.video || '');
                // setImages(selectedProduct.images || []);

                // Format the images array to match the format expected by ImageUploading component
                const formattedImages = selectedProduct.images.map((image, index) => ({
                    file: `${API_LINK}/${image}`, // Assuming that the image URLs are stored in 'images' array
                    key: index, // Add a unique key for each image
                    data_url: `${API_LINK}/${image}`, // Assuming that the image URLs are stored in 'images' array
                }));

                setImages(formattedImages);
            }
        }
    }, [open, selectedProduct]);


    const addProductHandler = () => {
        setLoading(true);
        const formData = new FormData();
        if (name && description && stockQuantity && price && category && type && images.length > 0) {
            formData.append('name', name);
            formData.append('description', description);
            formData.append('price', price);
            formData.append('stockQuantity', stockQuantity);
            formData.append('category', category);
            formData.append('type', type);
            formData.append('specification', specification);
            formData.append('color', color);
            formData.append('video', video);
            images.forEach((image) => {
                formData.append(`images`, image.file);
            });


            const requestConfig = {
                headers: {
                    Authorization: `Bearer ${authTokens?.accessToken}`
                }
            };

            if (selectedProduct) {
                axios.patch(`${API_LINK}/products/${selectedProduct?.id}`, formData, requestConfig).then(({ data }) => {
                    setProducts(products => products.map(product => product.id === data.id ? data : product));
                    // closeModalHandler();
                    alert('Votre produit a bien été modifié')
                }).catch((error) => {
                    console.log(error);
                }).finally(() => {
                    setLoading(false);
                });
            } else {
                axios.post(`${API_LINK}/products`, formData, requestConfig).then(({ data }) => {
                    setProducts(products => [data, ...products]);
                    // closeModalHandler();
                    alert('Votre produit a bien été ajouté')
                }).catch((error) => {
                    console.log(error);
                }).finally(() => {
                    setLoading(false);
                });
            }
        } else {
            alert("Veuillez remplir tous les champs et ajouter au moins une image.");
        }
    }


    const closeModalHandler = () => {
        handleOpen();
        setName('');
        setDescription('');
        setImages([]);
        setPrice('');
        setStockQuantity('');
        setCategory('');
        setType('');
        setVideo('');
        setSpecification('');
        setColor('');
        setSelectedProduct(null);
    }


    const onChange = (imageList, addUpdateIndex) => {
        setImages(imageList);
    };


    return (
        <>
            <Dialog
                size="lg"
                open={open}
                // handler={closeModalHandler}
                className="bg-transparent shadow-none"
            >
                {!loading ? (<Card className="mx-auto w-full">
                    <CardBody className="flex flex-col gap-4">
                        <div className="flex items-center justify-between">
                            <Typography variant="h4" color="blue-gray">
                                {selectedProduct ? "Modifier le produit" : "Ajouter un produit"}
                            </Typography>
                            <div className="absolute top-0 right-0">
                                <IconButton onClick={() => { closeModalHandler() }} variant="text" className="rounded-none">
                                    <BsX size={32} />
                                </IconButton>
                            </div>
                        </div>

                        <div className="grid md:grid-cols-3 grid-cols-1 gap-4 w-full">
                            <ImageUploading
                                multiple
                                value={images}
                                onChange={onChange}
                                maxNumber={10}
                                dataURLKey="data_url"
                            >
                                {({
                                    imageList,
                                    onImageUpload,
                                    onImageRemoveAll,
                                    onImageUpdate,
                                    onImageRemove,
                                    dragProps,
                                }) => (
                                    <div>
                                        {imageList.length !== 0 ?
                                            <Button
                                                fullWidth
                                                size="md"
                                                className="mb-2 bg-[#E1B6A5] shadow-none"
                                                onClick={onImageUpload}
                                                {...dragProps}
                                            >
                                                Rajouter d'autres images
                                            </Button> :
                                            <label className="flex rounded-lg border-4 border-dashed w-full p-10 group text-center">
                                                <div className="h-full w-full text-center flex justify-center items-center">
                                                    <PhotoIcon className="h-[120px] text-gray-200" />
                                                </div>
                                                <button className="hidden" onClick={onImageUpload} {...dragProps}></button>
                                            </label>

                                        }
                                        <div className="max-h-64 overflow-y-auto">
                                            <div className="flex flex-wrap gap-4">
                                                {imageList.map((image, index) => (
                                                    <div key={index} className="image-item">
                                                        <img src={image['data_url']} alt="" width="100" />
                                                        <div className="flex justify-center items-center gap-2 p-2">
                                                            <button className="p-2 flex justify-center items-center text-white bg-[#E1B6A5] rounded-lg"
                                                                onClick={() => onImageUpdate(index)}
                                                            >
                                                                <BsPencil className="text-base" />
                                                            </button>

                                                            <button
                                                                className="p-2 bg-red-400 flex justify-center items-center text-white rounded-lg"
                                                                onClick={() => onImageRemove(index)}
                                                            >
                                                                <BsTrash className="text-base" />
                                                            </button>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                        {imageList.length !== 0 && <Button onClick={onImageRemoveAll} fullWidth variant="filled" className="bg-red-400 shadow-none mt-2">Supprimer toutes les images</Button>}
                                    </div>
                                )}
                            </ImageUploading>



                            <div className="sm:col-span-2 col-span-1 max-h-96 overflow-y-auto">
                                <div className="grid sm:grid-cols-2 grid-cols-1 gap-4">
                                    <Input label="Nom du produit" type="text" value={name} onChange={(e) => setName(e.target.value)} />

                                    <Select label="Catégorie" value={category} onChange={(e) => setCategory(e)}>
                                        <Option value="diffuseur">Diffuseur</Option>
                                        <Option value="parfum">Parfum</Option>
                                        <Option value="candle-reed">Candles & Reed</Option>
                                    </Select>

                                    {category === "diffuseur" && <Input label="Couleur" type="text" value={color} onChange={(e) => setColor(e.target.value)} />}

                                    {category === "diffuseur" && (
                                        <Select label="Type" value={type} onChange={(e) => setType(e)}>
                                            <Option value="business">Business</Option>
                                            <Option value="home">Home</Option>
                                        </Select>
                                    )}

                                    {category === "parfum" && (
                                        <Select label="Type" value={type} onChange={(e) => setType(e)}>
                                            <Option value="oriental">Oriental</Option>
                                            <Option value="fresh">Fresh</Option>
                                            <Option value="floral">Floral</Option>
                                        </Select>
                                    )}

                                    {category === "candle-reed" && (
                                        <Select label="Type" value={type} onChange={(e) => setType(e)}>
                                            <Option value="type1">Type 1</Option>
                                            <Option value="type2">Type 2</Option>
                                            <Option value="type3">Type 3</Option>
                                        </Select>
                                    )}

                                    {(category === "diffuseur" && type === "home") && (
                                        <Select label="Spécification" value={specification} onChange={(e) => setSpecification(e)}>
                                            <Option value="home">Home</Option>
                                            <Option value="voiture">Voiture</Option>
                                            <Option value="sanitaire">Sanitaire</Option>
                                        </Select>
                                    )}

                                    {(category === "diffuseur" && type === "business") && (
                                        <Select label="Spécification" value={specification} onChange={(e) => setSpecification(e)}>
                                            <Option value="200ml">Jusqu' à 200 ml</Option>
                                            <Option value="300ml">Jusqu' à 300 ml</Option>
                                            <Option value="400ml">Jusqu' à 400 ml</Option>
                                        </Select>
                                    )}


                                    {(category === "parfum") && (
                                        <Select label="Spécification" value={specification} onChange={(e) => setSpecification(e)}>
                                            <Option value="50ml">Jusqu' à 50 ml</Option>
                                            <Option value="250ml">Jusqu' à 250 ml</Option>
                                            <Option value="500ml">Jusqu' à 500 ml</Option>
                                        </Select>
                                    )}

                                    <Input label="Quantité en stock" type="number" min={0} value={stockQuantity} onChange={(e) => setStockQuantity(e.target.value)} />
                                    <Input label="Prix" type="number" min={0} value={price} onChange={(e) => setPrice(e.target.value)} />

                                    <div className="sm:col-span-2 col-span-1">
                                        <ReactQuill theme="snow" className="view ql-editor" value={description} onChange={setDescription} />
                                    </div>

                                    <div className="sm:col-span-2 col-span-1">
                                        <Input label="Lien youtube" type="text" value={video} onChange={(e) => setVideo(e.target.value)} />
                                    </div>

                                </div>
                            </div>

                        </div>

                    </CardBody>
                    <CardFooter className="pt-0">
                        <Button variant="filled" className="bg-[#E1B6A5] shadow-none" onClick={() => addProductHandler()} fullWidth>
                            {selectedProduct ? "Modifier" : "Ajouter"}
                        </Button>

                    </CardFooter>
                </Card>
                ) : (
                    <div className="flex justify-center items-center p-5">
                        <Spinner />
                    </div>
                )}
            </Dialog >
        </>
    );
}
