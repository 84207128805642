import React, { createContext, useState, useEffect, useContext } from "react";

export const CartContext = createContext();

const CartProvider = ({ children }) => {
  // cart state
  const [cart, setCart] = useState([]);
  // item amount state
  const [itemAmount, setItemAmount] = useState(0);
  // total price state
  const [total, setTotal] = useState(0);

  useEffect(() => {
    const total = cart.reduce((accumulator, currentItem) => {
      return accumulator + currentItem.price * currentItem.amount;
    }, 0);
    setTotal(total);
  });

  // update item amount
  useEffect(() => {
    if (cart) {
      const amount = cart.reduce((accumulator, currentItem) => {
        return accumulator + currentItem.amount;
      }, 0);
      setItemAmount(amount);
    }
  }, [cart]);


  // const addToCart = (product, id) => {
  //   // Vérifier si la quantité sélectionnée dépasse la quantité en stock
  //   if (product.stockQuantity < 1) {
  //     console.log("Le produit est en rupture de stock");
  //     return; // Arrêter l'exécution de la fonction si le produit est en rupture de stock
  //   }

  //   // Vérifier si le produit est déjà dans le panier
  //   const cartItem = cart.find((item) => item.id === id);

  //   if (cartItem) {
  //     // Calculer la nouvelle quantité si ajoutée à la quantité existante
  //     const newQuantity = cartItem.amount + 1;
  //     // Vérifier si la nouvelle quantité dépasse la quantité en stock
  //     if (newQuantity <= product.stockQuantity) {
  //       const newCart = cart.map((item) =>
  //         item.id === id ? { ...item, amount: newQuantity } : item
  //       );
  //       setCart(newCart);
  //     } else {
  //       console.log("La quantité sélectionnée dépasse la quantité en stock");
  //     }
  //   } else {
  //     // Si le produit n'est pas dans le panier, l'ajouter avec une quantité de 1
  //     setCart([...cart, { ...product, amount: 1 }]);
  //   }
  // };

  const addToCart = (product, id, quantityToAdd) => {
    // Vérifier si la quantité sélectionnée dépasse la quantité en stock
    if (product.stockQuantity < 1) {
      console.log("Le produit est en rupture de stock");
      return; // Arrêter l'exécution de la fonction si le produit est en rupture de stock
    }

    // Vérifier si le produit est déjà dans le panier
    const cartItem = cart.find((item) => item.id === id);

    if (cartItem) {
      // Calculer la nouvelle quantité si ajoutée à la quantité existante
      const newQuantity = cartItem.amount + quantityToAdd;
      // Vérifier si la nouvelle quantité dépasse la quantité en stock
      if (newQuantity <= product.stockQuantity) {
        const newCart = cart.map((item) =>
          item.id === id ? { ...item, amount: newQuantity } : item
        );
        setCart(newCart);
      } else {
        console.log("La quantité sélectionnée dépasse la quantité en stock");
      }
    } else {
      // Si le produit n'est pas dans le panier, l'ajouter avec la quantité sélectionnée
      setCart([...cart, { ...product, amount: quantityToAdd }]);
    }
  };



  // remove from cart
  const removeFromCart = (id) => {
    const newCart = cart.filter((item) => {
      return item.id !== id;
    });
    setCart(newCart);
  };

  // cleart cart
  const clearCart = () => {
    setCart([]);
  };

  // increase amount
  const increaseAmount = (id) => {
    const cartItem = cart.find((item) => item.id === id);
    addToCart(cartItem, id, 1);
  };

  // decrease amount
  const decreaseAmount = (id) => {
    const cartItem = cart.find((item) => item.id === id);
    if (cartItem) {
      const newCart = cart.map((item) => {
        if (item.id === id) {
          return { ...item, amount: cartItem.amount - 1 };
        } else {
          return item;
        }
      });
      setCart(newCart);
    }
    if (cartItem.amount < 2) {
      removeFromCart(id);
    }
  };

  return (
    <CartContext.Provider
      value={{
        cart,
        addToCart,
        removeFromCart,
        clearCart,
        increaseAmount,
        decreaseAmount,
        itemAmount,
        total,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export default CartProvider;
