import React from 'react'
import { Card, CardBody, CardFooter } from '@material-tailwind/react'
import { API_LINK } from '../../apiConfig'
import { BsPencil, BsTrash } from 'react-icons/bs'

const ProductCard = ({ data, setSelectedProduct, handleOpenAddProduct, handleOpenDeleteProduct }) => {
    return (
        <Card shadow={false} className='bg-secondary'>
            <CardBody className="border-b border-primary h-[300px] relative overflow-hidden group transition">
                <div className="w-full h-full flex justify-center items-center">
                    {/* image */}
                    <div className="w-[200px] mx-auto flex justify-center items-center">
                        <img
                            className="max-h-[200px] group-hover:scale-110 transition duration-300"
                            src={`${API_LINK}/${data.images[0]}`}
                            alt={data.name}
                        />
                    </div>
                </div>
                {/* buttons */}
                <div className="absolute top-6 -right-11 group-hover:right-5 p-2 flex flex-col justify-center items-center gap-y-2 opacity-0 group-hover:opacity-100 transition-all duration-300">

                    <button className="flex justify-center items-center text-white w-12 h-12 bg-[#E1B6A5] rounded-lg"
                        onClick={() => { setSelectedProduct(data); handleOpenAddProduct(); }}
                    >
                        <BsPencil className="text-xl" />
                    </button>

                    <button
                        className="w-12 h-12 bg-red-400 flex justify-center items-center text-white rounded-lg"
                        onClick={() => { setSelectedProduct(data); handleOpenDeleteProduct(); }}
                    >
                        <BsTrash className="text-xl" />
                    </button>
                </div>
            </CardBody>
            {/* category, title & price */}
            <CardFooter>
                <div className="tex-sm capitalize text-gray-500 mb-1">{data.category}{data.type && ` - ${data.type}`}</div>
                <div>
                    <h2 className="font-semibold capitalize mb-1">{data.name}{data.specification && ` - ${data.specification}`}</h2>
                </div>

                <div className='flex items-center justify-between'>
                    <h2 className="font-semibbold">{data.price} DZD</h2>
                    <h2 className={`${data.stockQuantity === 0 && "text-red-400"} font-semibbold`}>{data.stockQuantity} Unités</h2>
                </div>
            </CardFooter>
        </Card>
    )
}

export default ProductCard