import { Link, NavLink } from "react-router-dom";
import { HomeIcon, ShoppingBagIcon, ShoppingCartIcon, UsersIcon, MegaphoneIcon } from "@heroicons/react/24/outline";
import {
    Button,
    Typography,
} from "@material-tailwind/react";

import Logo from "../../img/logo.png";

import { useMaterialTailwindController } from "../../contexts/MaterialContext"
import { useContext, useState } from "react";
import { AddUpdateDeleteAdvertisement } from "../../widgets/modals/advertisements/AddUpdateDeleteAdvertisement";
import { AuthContext } from "../../contexts/AuthContext";


const Sidenav = () => {
    const [controller] = useMaterialTailwindController();
    const { openSidenav } = controller;
    const { authTokens } = useContext(AuthContext);



    const [openAdvertisement, setOpenAdvertisement] = useState(false);
    const handleOpenAdvertisement = () => setOpenAdvertisement((cur) => !cur);


    return (
        <aside
            className={`${openSidenav ? "translate-x-0" : "-translate-x-80"
                } fixed inset-0 z-50 h-screen w-72 rounded-none transition-transform duration-300 lg:translate-x-0 bg-secondary`}
        >
            <div
                className={`relative`}
            >
                <Link to="/" className="py-6 px-8 flex items-center justify-center">
                    <div className="w-36">
                        <img src={Logo} alt="" />
                    </div>
                </Link>
            </div>
            <div className="m-4">
                <ul className="mb-4 flex flex-col gap-1">
                    <NavLink to={`/dashboard/`}>
                        {({ isActive }) => (
                            <Button
                                variant={isActive ? "filled" : "text"}

                                className={`${isActive ? "bg-[#E1B6A5] text-white" : "bg-transparent text-primary"} flex items-center gap-4 px-4 capitalize shadow-none`}
                                fullWidth
                            >
                                <HomeIcon className="w-6" />
                                <Typography
                                    color="inherit"
                                    className="font-medium capitalize"
                                >
                                    Tableau de bord
                                </Typography>
                            </Button>
                        )}
                    </NavLink>
                    {authTokens.user?.role === 'admin' && <NavLink to={`/dashboard/utilisateurs`}>
                        {({ isActive }) => (
                            <Button
                                variant={isActive ? "filled" : "text"}

                                className={`${isActive ? "bg-[#E1B6A5] text-white" : "bg-transparent text-primary"} flex items-center gap-4 px-4 capitalize shadow-none`}
                                fullWidth
                            >
                                <UsersIcon className="w-6" />
                                <Typography
                                    color="inherit"
                                    className="font-medium capitalize"
                                >
                                    Liste d'utilisateurs
                                </Typography>
                            </Button>
                        )}
                    </NavLink>}
                    <NavLink to={`/dashboard/produits`}>
                        {({ isActive }) => (
                            <Button
                                variant={isActive ? "filled" : "text"}

                                className={`${isActive ? "bg-[#E1B6A5] text-white" : "bg-transparent text-primary"} flex items-center gap-4 px-4 capitalize shadow-none`}
                                fullWidth
                            >
                                <ShoppingBagIcon className="w-6" />
                                <Typography
                                    color="inherit"
                                    className="font-medium capitalize"
                                >
                                    Liste de produits
                                </Typography>
                            </Button>
                        )}
                    </NavLink>
               
                    <NavLink to={`/dashboard/commandes`}>
                        {({ isActive }) => (
                            <Button
                                variant={isActive ? "filled" : "text"}

                                className={`${isActive ? "bg-[#E1B6A5] text-white" : "bg-transparent text-primary"} flex items-center gap-4 px-4 capitalize shadow-none`}
                                fullWidth
                            >
                                <ShoppingCartIcon className="w-6" />
                                <Typography
                                    color="inherit"
                                    className="font-medium capitalize"
                                >
                                    Liste de commandes
                                </Typography>
                            </Button>
                        )}
                    </NavLink>
                    <Button
                        variant={"text"}
                        onClick={handleOpenAdvertisement}
                        className={`bg-transparent text-primary flex items-center gap-4 px-4 capitalize`}
                        fullWidth
                    >
                        <MegaphoneIcon className="w-6" />
                        <Typography
                            color="inherit"
                            className="font-medium capitalize"
                        >
                            Annonce publicitaire
                        </Typography>
                    </Button>
                </ul>
            </div>

            <AddUpdateDeleteAdvertisement open={openAdvertisement} handleOpen={handleOpenAdvertisement} />

        </aside>
    );
}


export default Sidenav;
