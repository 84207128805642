import React, { useEffect, useRef, useState } from 'react';
import { Typography } from '@material-tailwind/react';
import { motion, useInView, useAnimation } from 'framer-motion'

import video from '../../img/home/video.mp4';
import banner from '../../img/home/banner.png';

import premium from '../../img/home/premium.png';
import business from '../../img/home/business.png';
import home from '../../img/home/home.png';

import Marks from '../../components/home/Marks';
import { Link } from 'react-router-dom';
import { API_LINK } from '../../apiConfig';
import axios from 'axios';
import { ShowAdvertisement } from '../../widgets/modals/advertisements/ShowAdvertisement';



const Home = () => {
    const videoControls = useAnimation()
    const refvideo = useRef(null);
    const isInViewVideo = useInView(refvideo);

    const textControls = useAnimation()
    const refText = useRef(null);
    const isInViewText = useInView(refText);

    const technologieControls = useAnimation()
    const refTechnologie = useRef(null);
    const isInViewTechnologie = useInView(refTechnologie);


    useEffect(() => {
        if (isInViewVideo) {
            videoControls.start("visible")
        }

        if (isInViewText) {
            textControls.start("visible")
        }

        if (isInViewTechnologie) {
            technologieControls.start("visible")
        }
    }, [isInViewVideo, isInViewText, isInViewTechnologie])



    const [isActive, setIsActive] = useState(false);
    const [openShowAdvertisement, setOpenShowAdvertisement] = useState(false);
    const handleOpenShowAdvertisement = () => setOpenShowAdvertisement((cur) => !cur);
    const [advertisement, setAdvertisement] = useState();


    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        window.addEventListener("scroll", () => {
            window.scrollY > 60 ? setIsActive(true) : setIsActive(false);
        });
    });


    useEffect(() => {
        axios.get(`${API_LINK}/advertisements`).then(({ data }) => {
            setAdvertisement(data);
            if (data) {
                setOpenShowAdvertisement(true)
            }
        }).catch((error) => {
            console.log(error);
        })
    }, [])

    return (
        <div>
            <div>
                <div
                    className={`${isActive ? 'h-[72px]' : 'h-[88px]'} top-0 bg-white fixed w-full transition-all`}
                ></div>

                <div className='sm:mt-0 mt-[96px] text-info'>
                    {/* sm:mt-[110px] mt-[96px] */}

                    <div ref={refvideo}>
                        <motion.section
                            variants={{
                                hidden: { opacity: 0, y: 75 },
                                visible: { opacity: 1, y: 0 }
                            }}
                            initial='hidden'
                            animate={videoControls}
                            transition={{ duration: 0.5, delay: 0.25 }}
                            className='relative'>
                            <div>
                                <video src={video} autoPlay loop muted className='w-full object-cover' />
                            </div>
                        </motion.section>
                    </div>

                    <div ref={refText}>
                        <motion.section
                            variants={{
                                hidden: { opacity: 0, y: 75 },
                                visible: { opacity: 1, y: 0 }
                            }}
                            initial='hidden'
                            animate={textControls}
                            transition={{ duration: 0.5, delay: 0.25 }} x
                            className="mt-10 relative">
                            <div className="mx-auto grid grid-cols-1 sm:grid-cols-2 gap-6 relative">
                                <div className='sm:py-32 py-16 flex justify-center'>
                                    <Typography className='text-primary uppercase font-secondary font-light sm:text-3xl text-xl text-center tracking-wider w-2/3'>
                                        Distinguez-vous avec nos solutions olfactives exclusives
                                    </Typography>

                                </div>

                                <div className='bg-gradient-to-tr from-[#E6D9CF] to-[#F8F5F3] sm:py-32 py-16 flex justify-center'>
                                    <Typography className='text-info w-2/3'>
                                        Notre savoir-faire, fruit de dix années
                                        d’expérience et d’investissements en
                                        recherche et développement en
                                        fragrance, afin de donner à chaque lieu
                                        une expérience sensorielle olfactive
                                        unique.
                                    </Typography>
                                </div>
                            </div>

                            <div className='flex justify-center bottom-10 absolute inset-x-0'>
                                <hr className='sm:w-2/5 w-2/3 border-2 border-[#C9BAAD]'></hr>
                            </div>
                        </motion.section>
                    </div>

                    <div ref={refTechnologie}>
                        <motion.section
                            variants={{
                                hidden: { opacity: 0, y: 75 },
                                visible: { opacity: 1, y: 0 }
                            }}
                            initial='hidden'
                            animate={technologieControls}
                            transition={{ duration: 0.5, delay: 0.25 }}
                            className="mt-20">
                            <div className='container mx-auto relative'>
                                <img src={banner} className="w-full h-auto" />
                                <div className='absolute inset-0 sm:w-1/2 flex justify-center items-center p-8'>
                                    <div className='flex flex-col'>
                                        <Typography variant='h3' className='text-primary tracking-wider font-secondary font-medium sm:text-left text-center hidden sm:block'>
                                            UNE TECHNOLOGIE DE POINTE
                                        </Typography>

                                        <p className='text-white mt-4 hidden sm:block'>
                                            Nos diffuseurs de parfum ultra-silencieux utilisent un nouveau et innovant
                                            technologie appelée Cold-Air Diffusion, libérant les
                                            vers le bas des huiles dans les conduits CVC envoyant un flux constant et
                                            parfum équilibré à toutes les zones souhaitées. Nos diffuseurs ont
                                            de nombreuses fonctions automatisées, permettant un faible
                                            expérience en maintenance et suppression de toute courbe d’apprentissage
                                            pour parfumer votre espace.
                                        </p>

                                    </div>
                                </div>
                            </div>
                        </motion.section>
                    </div>


                    <section className='flex justify-center items-center p-8 sm:hidden'>
                        <div className='flex flex-col'>
                            <Typography variant='h4' className='text-primary tracking-wider font-secondary font-medium sm:text-left text-center'>
                                UNE TECHNOLOGIE DE POINTE
                            </Typography>

                            <Typography className='text-text text-justify mt-4'>
                                Nos diffuseurs de parfum ultra-silencieux utilisent un nouveau et innovant
                                technologie appelée Cold-Air Diffusion, libérant les
                                vers le bas des huiles dans les conduits CVC envoyant un flux constant et
                                parfum équilibré à toutes les zones souhaitées. Nos diffuseurs ont
                                de nombreuses fonctions automatisées, permettant un faible
                                expérience en maintenance et suppression de toute courbe d’apprentissage
                                pour parfumer votre espace.
                            </Typography>

                        </div>
                    </section>


                    <section className="mt-10 py-5">
                        <div className='container mx-auto relative'>
                            <Typography className='font-normal font-secondary tracking-wider text-center sm:text-3xl text-2xl text-primary'>
                                LA CONFIANCE DE NOS CLIENTS <br></br>
                                EST LE REFLET DE NOTRE TRAVAIL EXCEPTIONNEL
                            </Typography>


                            <Typography variant='paragraph' className='font-normal text-center text-info mt-4'>
                                Partenaire de nombreux hôtels, cliniques et d’autres entreprises dans différents <br></br>
                                secteurs d’activité.
                            </Typography>
                        </div>
                    </section>


                    <section className="my-10 pb-10">
                        <div className='flex justify-center'>
                            <hr className='sm:w-2/5 w-2/3 border-primary'></hr>
                        </div>
                        <div className='py-6'>
                            <Marks />
                        </div>
                        <div className='flex justify-center'>
                            <hr className='sm:w-2/5 w-2/3 border-primary'></hr>
                        </div>
                    </section>


                    <section className="relative">
                        <div className='bg-[#C9BAAD]'>
                            <div className='container mx-auto flex flex-col items-center pt-10 pb-32'>
                                <Typography className='text-white font-secondary tracking-wider sm:text-3xl text-xl font-normal text-center'>
                                    UNE EMPREINTE OLFACTIVE <br></br>
                                    POUR TOUS LES ESPACES
                                </Typography>
                            </div>
                        </div>


                        <div className='container mx-auto -mt-20 mb-20'>
                            <div className="grid sm:grid-cols-2 grid-cols-1 gap-6 mt-4 text-info">
                                <Link to='premium' className='col-span-1 sm:col-span-2 relative hover:opacity-80 transition duration-500'>
                                    <img src={premium} />
                                    <div className='absolute inset-0 flex justify-center items-center'>
                                        <Typography className="text-center text-white sm:text-3xl text-xl font-semibold">PREMIUM</Typography>
                                    </div>
                                </Link>
                                <Link to='business' className='col-span-1 relative hover:opacity-80 transition duration-500'>
                                    <img src={business} />
                                    <div className='absolute inset-0 flex justify-center items-center'>
                                        <Typography className="text-center text-white sm:text-3xl text-xl font-semibold">BUSINESS</Typography>
                                    </div>
                                </Link>
                                <Link to='senthome' className='col-span-1 relative hover:opacity-80 transition duration-500'>
                                    <img src={home} />
                                    <div className='absolute inset-0 flex justify-center items-center'>
                                        <Typography className="text-center text-white sm:text-3xl text-xl font-semibold">HOME</Typography>
                                    </div>
                                </Link>
                            </div>
                        </div>

                    </section>
                </div>

                <ShowAdvertisement open={openShowAdvertisement} handleOpen={handleOpenShowAdvertisement} advertisement={advertisement} />
            </div>
        </div>
    )
}

export default Home