import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { API_LINK } from '../../apiConfig';
import Product from '../../components/Product';
import { Tab, TabPanel, Tabs, TabsBody, TabsHeader, Typography } from '@material-tailwind/react';
import { BsSearch } from 'react-icons/bs';
import { NavLink, useLocation } from 'react-router-dom';

const Parfum = () => {
    const location = useLocation();

    const [isActive, setIsActive] = useState(false);
    const [products, setProducts] = useState([]);


    const searchParams = new URLSearchParams(location.search);
    const typeparfum = searchParams.get('type');
    const [type, setType] = useState(typeparfum || undefined);


    const [specification, setSpecification] = useState();
    const [sortOrder, setSortOrder] = useState();


    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        window.addEventListener("scroll", () => {
            window.scrollY > 60 ? setIsActive(true) : setIsActive(false);
        });
    });

    useEffect(() => {
        axios.get(`${API_LINK}/products/search?category=parfum&type=${type}&specification=${specification}&priceOrder=${sortOrder}`).then(({ data }) => {
            setProducts(data);
        }).catch((error) => {
            console.log(error);
        })
    }, [type, specification, sortOrder]);

    const handleTabSelect = (tabValue) => {
        setType(tabValue);
        setSpecification('');
    };

    return (
        <div>
            <div
                className={`${isActive ? 'h-[72px]' : 'h-[88px]'} top-0 bg-white fixed w-full transition-all`}
            ></div>

            <div className='sm:mt-[110px] mt-[96px] text-info'>


                <div className='bg-secondary w-full flex justify-center rounded-none mt-5 p-5'>
                    <div className='flex sm:flex-row flex-col items-center text-primary'>
                        <NavLink to="/diffuser" className='text-center rounded-none w-40 hover:bg-white'>
                            {({ isActive }) => (
                                <Typography
                                    className={`${isActive && "border-b-2"} border-primary p-2 font-normal`}
                                    variant="paragraph"
                                >
                                    Diffuseurs
                                </Typography>
                            )}
                        </NavLink>
                        <NavLink to="/parfum" className='text-center rounded-none w-40 hover:bg-white'>
                            {({ isActive }) => (
                                <Typography
                                    className={`${isActive && "border-b-2"} border-primary p-2 font-normal`}
                                    variant="paragraph"
                                >
                                    Parfums
                                </Typography>
                            )}
                        </NavLink>

                        <NavLink to="/candle-reed" className='text-center rounded-none w-40 hover:bg-white'>
                            {({ isActive }) => (
                                <Typography
                                    className={`${isActive && "border-b-2"} border-primary p-2 font-normal`}
                                    variant="paragraph"
                                >
                                    Candles & Reed
                                </Typography>
                            )}
                        </NavLink>
                    </div>
                </div>

                <section className="py-10">
                    <div className="container mx-auto">
                        {/* le titre doit etre dynamique */}
                        <Typography variant='h3' className="mb-10 text-center">CHOISIR VOTRE PARFUM</Typography>

                        <Tabs value={type}>
                            <TabsHeader className='bg-white rounded-none p-0 sm:w-1/3 max-w-full mx-auto gap-4' indicatorProps={{ className: 'shadow-none rounded-none bg-secondary text-primary' }}>
                                <Tab value={'oriental'} className={`${type !== 'oriental' && 'bg-[#F7F4F0]'} p-2`} onClick={() => handleTabSelect('oriental')}>
                                    ORIENTAL
                                </Tab>
                                <Tab value={'fresh'} className={`${type !== 'fresh' && 'bg-[#F7F4F0]'} p-2`} onClick={() => handleTabSelect('fresh')}>
                                    FRESH
                                </Tab>
                                <Tab value={'floral'} className={`${type !== 'floral' && 'bg-[#F7F4F0]'} p-2`} onClick={() => handleTabSelect('floral')}>
                                    FLORAL
                                </Tab>
                            </TabsHeader>
                            <TabsBody>
                                <TabPanel value={'oriental'}>
                                    <div className='w-full flex flex-wrap sm:justify-between justify-center items-center'>
                                        {/* <select value={specification} className={`px-2 py-1 border placeholder:font-light bg-white border-gray-400`} onChange={(e) => setSpecification(e.target.value)}>
                                            <option value='' disabled selected>Filtrez</option>
                                            <option value='50ml'>50 ml</option>
                                            <option value='100ml'>100 ml</option>
                                            <option value='150ml'>150 ml</option>
                                        </select> */}

                                        <Tabs value={specification} >
                                            <TabsHeader className='bg-secondary rounded-none p-0 mx-auto mb-2' indicatorProps={{ className: 'shadow-none rounded-none bg-secondary text-primary' }}>
                                                <Tab value={'50ml'} className='w-fit' onClick={() => setSpecification('50ml')}>
                                                    50 ml
                                                </Tab>
                                                <Tab value={'250ml'} className='w-fit' onClick={() => setSpecification('250ml')}>
                                                    250 ml
                                                </Tab>
                                                <Tab value={'500ml'} className='w-fit' onClick={() => setSpecification('500ml')}>
                                                    500 ml
                                                </Tab>
                                            </TabsHeader>
                                        </Tabs>

                                        <select value={sortOrder} className={`px-2 py-1 border placeholder:font-light bg-white border-gray-400`} onChange={(e) => setSortOrder(e.target.value)}>
                                            <option value=''>Triez par prix</option>
                                            <option value="asc">Du moins cher</option>
                                            <option value="desc">Du plus cher</option>
                                        </select>
                                    </div>
                                    {products.length !== 0 ? (<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-[30px] max-w-sm mx-auto md:max-w-none mt-6">
                                        {products.map((product) => {
                                            return (
                                                <Product product={product} key={product.id} />
                                            );
                                        })}
                                    </div>) : (
                                        <div className='flex flex-col justify-center items-center text-info'>
                                            <BsSearch size={150} className='text-gray-200 m-5' />
                                            <Typography variant='h4' className='text-center'>
                                                Nous n'avons trouvez aucun resultat pour votre recherche.
                                            </Typography>
                                        </div>)}
                                </TabPanel>
                                <TabPanel value={'fresh'}>
                                    <div className='w-full flex flex-wrap sm:justify-between justify-center items-center'>
                                        <Tabs value={specification} >
                                            <TabsHeader className='bg-secondary rounded-none p-0 mx-auto mb-2' indicatorProps={{ className: 'shadow-none rounded-none bg-secondary text-primary' }}>
                                                <Tab value={'50ml'} className='w-fit' onClick={() => setSpecification('50ml')}>
                                                    50 ml
                                                </Tab>
                                                <Tab value={'250ml'} className='w-fit' onClick={() => setSpecification('250ml')}>
                                                    250 ml
                                                </Tab>
                                                <Tab value={'500ml'} className='w-fit' onClick={() => setSpecification('500ml')}>
                                                    500 ml
                                                </Tab>
                                            </TabsHeader>
                                        </Tabs>

                                        <select value={sortOrder} className={`px-2 py-1 border placeholder:font-light bg-white border-gray-400`} onChange={(e) => setSortOrder(e.target.value)}>
                                            <option value=''>Triez par prix</option>
                                            <option value="asc">Du moins cher</option>
                                            <option value="desc">Du plus cher</option>
                                        </select>
                                    </div>
                                    {products.length !== 0 ? (<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-[30px] max-w-sm mx-auto md:max-w-none mt-6">
                                        {products.map((product) => {
                                            return (
                                                <Product product={product} key={product.id} />
                                            );
                                        })}
                                    </div>) : (
                                        <div className='flex flex-col justify-center items-center text-info'>
                                            <BsSearch size={150} className='text-gray-200 m-5' />
                                            <Typography variant='h4' className='text-center'>
                                                Nous n'avons trouvez aucun resultat pour votre recherche.
                                            </Typography>
                                        </div>)}
                                </TabPanel>
                                <TabPanel value={'floral'}>
                                    <div className='w-full flex flex-wrap sm:justify-between justify-center items-center'>
                                        <Tabs value={specification} >
                                            <TabsHeader className='bg-secondary rounded-none p-0 mx-auto mb-2' indicatorProps={{ className: 'shadow-none rounded-none bg-secondary text-primary' }}>
                                                <Tab value={'50ml'} className='w-fit' onClick={() => setSpecification('50ml')}>
                                                    50 ml
                                                </Tab>
                                                <Tab value={'250ml'} className='w-fit' onClick={() => setSpecification('250ml')}>
                                                    250 ml
                                                </Tab>
                                                <Tab value={'500ml'} className='w-fit' onClick={() => setSpecification('500ml')}>
                                                    500 ml
                                                </Tab>
                                            </TabsHeader>
                                        </Tabs>

                                        <select value={sortOrder} className={`px-2 py-1 border placeholder:font-light bg-white border-gray-400`} onChange={(e) => setSortOrder(e.target.value)}>
                                            <option value=''>Triez par prix</option>
                                            <option value="asc">Du moins cher</option>
                                            <option value="desc">Du plus cher</option>
                                        </select>
                                    </div>
                                    {products.length !== 0 ? (<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-[30px] max-w-sm mx-auto md:max-w-none mt-6">
                                        {products.map((product) => {
                                            return (
                                                <Product product={product} key={product.id} />
                                            );
                                        })}
                                    </div>) : (
                                        <div className='flex flex-col justify-center items-center text-info'>
                                            <BsSearch size={150} className='text-gray-200 m-5' />
                                            <Typography variant='h4' className='text-center'>
                                                Nous n'avons trouvez aucun resultat pour votre recherche.
                                            </Typography>
                                        </div>)}
                                </TabPanel>



                                {type !== 'oriental' && type !== 'fresh' && type !== 'floral' && (
                                    <TabPanel value={type}>
                                        <div className='w-full flex flex-wrap sm:justify-between justify-center items-center'>
                                            <Tabs value={specification} >
                                                <TabsHeader className='bg-secondary rounded-none p-0 mx-auto mb-2' indicatorProps={{ className: 'shadow-none rounded-none bg-secondary text-primary' }}>
                                                    <Tab value={'50ml'} className='w-fit' onClick={() => setSpecification('50ml')}>
                                                        50 ml
                                                    </Tab>
                                                    <Tab value={'250ml'} className='w-fit' onClick={() => setSpecification('250ml')}>
                                                        250 ml
                                                    </Tab>
                                                    <Tab value={'500ml'} className='w-fit' onClick={() => setSpecification('500ml')}>
                                                        500 ml
                                                    </Tab>
                                                </TabsHeader>
                                            </Tabs>

                                            <select value={sortOrder} className={`px-2 py-1 border placeholder:font-light bg-white border-gray-400`} onChange={(e) => setSortOrder(e.target.value)}>
                                                <option value=''>Triez par prix</option>
                                                <option value="asc">Du moins cher</option>
                                                <option value="desc">Du plus cher</option>
                                            </select>
                                        </div>
                                        {products.length !== 0 ? (<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-[30px] max-w-sm mx-auto md:max-w-none mt-6">
                                            {products.map((product) => {
                                                return (
                                                    <Product product={product} key={product.id} />
                                                );
                                            })}
                                        </div>) : (
                                            <div className='flex flex-col justify-center items-center text-info'>
                                                <BsSearch size={150} className='text-gray-200 m-5' />
                                                <Typography variant='h4' className='text-center'>
                                                    Nous n'avons trouvez aucun resultat pour votre recherche.
                                                </Typography>
                                            </div>)}
                                    </TabPanel>
                                )}


                            </TabsBody>
                        </Tabs>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default Parfum